body {
  background: url(./assets/wework-background.png);
  height: 100%;
  width: 100%;
}

.text1 {
  color: #333;
}

.text2 {
  color: #333;
  font-size: 1.25rem;
  line-height: 2rem;
}

.text3 {
  color: #333;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: bold;
}

.button {
  background-color: blue;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  border: .0625rem solid blue;
  border-radius: .25rem;
  cursor: pointer;
  padding: 0.25rem 1rem;
  white-space: nowrap;
  text-align: center;
  vertical-align: bottom;
}

.input {
  line-height: .25rem;
  padding: 0.25rem 1rem;
  border-radius: .25rem;
  border: 1px solid rgba(255,255,255,0);
}

.app-container {
  text-align: center;
  padding-top: 2rem;
}

.connection-form {
  padding-top: 2rem;
}

.connection-form form {
  padding-top: 1rem;
}

.connection-form form input {
  margin-right: 0.5rem;
}

.login-form {
  padding-top: 2rem;
}

.login-form button {
  margin-top: 1rem;
}

.profile-error {
  margin-top: 2rem;
}

.profile-error .text3 {
  margin-bottom: 1rem;
  color: red;
}

.profile {
  margin-top: 2rem;
}

.profile-claims {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.profile .text1 {
  text-align: justify;
}
